$.fn.calendario = function(){
	const 	hoje 	= new Date();
	let 	ano 	= hoje.getFullYear();
	let 	mes 	= hoje.getMonth();
	let 	dia 	= hoje.getDate();
	let 	_this 	= this;

	_this.Calendario = {
		anos 	: {},
		hoje 	: {
			dia 	: dia,
			mes 	: mes,
			ano 	: ano,
			full 	: `${dia}-${mes+1}-${ano}`,
			formato : 'dd-mm-aaaa'
		},
		meses 	: [
			'JANEIRO',
			'FEVEREIRO',
			'MARÇO',
			'ABRIL',
			'MAIO',
			'JUNHO',
			'JULHO',
			'AGOSTO',
			'SETEMBRO',
			'OUTUBRO',
			'NOVEMBRO',
			'DEZEMBRO'
		]
	}

	Object.freeze(_this.Calendario.hoje);

	return this.each(function(){
		let calendario 	= $(this);
		let hoje 		= _this.Calendario.hoje;
		let meses 		= _this.Calendario.meses;
		let prev 		= {
			mes : hoje.mes - 1,
			ano : hoje.ano
		};
		let next 		= {
			mes : hoje.mes + 1,
			ano : hoje.ano
		};

		if(hoje.mes == 0) {
			prev.mes = 11;
			prev.ano = hoje.ano - 1;
		}

		if(hoje.mes == 11) {
			next.mes = 0;
			next.ano = hoje.ano +1;
		}

		drawMeses({
			ano : hoje.ano,
			mes : hoje.mes,
			dia : hoje.dia
		});

		calendario.append(template(meses[hoje.mes],
			// ano atual
			hoje.ano,
			// dias do mês atual
			_this.Calendario.anos[hoje.ano][hoje.mes].template,
			// mês anterior
			`${prev.mes},${prev.ano}`,
			// próximo
			`${next.mes},${next.ano}`)
		);

		let controles = {
			all 	: calendario.find('.ctrl-mes'),
			next 	: calendario.find('[data-fn="next"]'),
			prev 	: calendario.find('[data-fn="prev"]')
		};

		controles.all.click(function(){
			let target 	= $(this).attr('data-target');
			let fn 		= $(this).data('fn');

			target = target.split(',');

			let mes 	= target[0];
			let ano 	= target[1];

			let anos = _this.Calendario.anos;

			if(!anos[ano]){
				drawMeses({
					ano : parseInt(ano),
					mes : parseInt(mes),
					dia : hoje.dia
				});
			}

			let targetMonth = anos[ano][mes];

			calendario
				.find('.nome')
				.html(`${targetMonth.name} - ${ano}`);
			calendario
				.find('.dias')
				.html(targetMonth.template);
			controles
				.prev
				.attr('data-target', `${targetMonth.prev.mes},${targetMonth.prev.ano}`)
			controles
				.next.attr('data-target', `${targetMonth.next.mes},${targetMonth.next.ano}`)
			calendario
				.find('[data-date="'+_this.Calendario.hoje.full+'"]')
				.addClass('active');

		});

		calendario
			.find('[data-date="'+_this.Calendario.hoje.full+'"]')
			.addClass('active');
		
	});

	function drawMeses (data) {
		let ano = data.ano;

		_this.Calendario.anos[ano] = [];

		for (let i=0;i<12;i++) {
			let primeiroDia = new Date(ano, i, 1).getDay();
			let mes = {
				name 	: _this.Calendario.meses[i],
				length 	: 32 - (new Date(ano, i, 32).getDate()),
				prev 	: {
					mes : i == 0 ? 11 : (i-1),
					ano : i == 0 ? (ano - 1) : ano
				},
				next 	: {
					mes : i == 11 ? 0 : (i+1),
					ano : i == 11 ? (ano + 1) : ano
				}
			};
			let diasTemplate = '';
			let classe = '';

			for (let d=0; d<mes.length; d++) {
				let fst = '';

				if(d==0){
					fst = `data-dia="${primeiroDia}"`;
				}

				let date = `${d+1}-${i+1}-${ano}`;
				
				diasTemplate += `<li ${classe} ${fst} data-date="${date}">${d+1}</li>\n`;
			}

			mes.template = diasTemplate;

			_this.Calendario.anos[ano].push(mes);
		}
	}

	function template (mes, ano, dias, prev, next){
		return `
			<div class="mes">
				<div class="calendario-head">
					<button class="prev ctrl-mes" data-fn="prev" data-target="${prev}" type="button" aria-label="Anterior">
						<i class="fas fa-chevron-left"></i>
					</button>
					
					<div class="nome">${mes} - ${ano}</div>

					<button class="next ctrl-mes"  data-fn="next" data-target="${next}" type="button" aria-label="Próximo">
						<i class="fas fa-chevron-right"></i>
					</button>
				</div>
				<ol class="dias-semana">
					<li aria-label="Domingo">D</li>
					<li aria-label="Segunda-feira">S</li>
					<li aria-label="Terça-feira">T</li>
					<li aria-label="Quarta-feira">Q</li>
					<li aria-label="Quinta-feira">Q</li>
					<li aria-label="Sexta-feira">S</li>
					<li aria-label="Sábado">S</li>
				</ol>
				<ol class="dias">
					<!-- Formato da data: dd-mm-aaaa -->
					${dias}
				</ol>
			</div>
		`
	};
};

$('.calendario').calendario();